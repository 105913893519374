<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <h4>
          {{ $t("defaultWebshops.countrySettings") }}
        </h4>
      </div>
      <div class="col-6 col-md-4">
        <input
          type="search"
          class="form-control py-0"
          v-model="filter"
          :placeholder="`Search countries..`"
        />
      </div>
    </div>
    <OneByOneTable
      v-model:rows="rows"
      v-model:items="filterCountries"
      type="Country"
      v-on:update:field="update"
      :show-add="false"
      :show-del="false"
      :key="key"
    />
  </div>
</template>

<script>
import http from "@/modules/http";
import OneByOneTable from "@/components/lists/OneByOneTable";
import { useStore } from "vuex";
import StateTypes from "../../../modules/StateTypes";

export default {
  name: "DefaultWebshops",
  components: { OneByOneTable },
  data() {
    return {
      key: 0,
      store: useStore(),
      countries: [],
      filter: "",
      rows: [
        {
          name: "ID",
          key: "number",
          type: "id",
          show: false,
        },
        {
          name: "Code",
          key: "code",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Country",
          key: "name",
          type: "string",
          show: true,
        },
        {
          name: "Region",
          key: "region",
          type: "enum",
          values: [
            "Africa",
            "Americas",
            "Antrartic",
            "Asia",
            "Europe",
            "Oceania",
          ],
          show: true,
        },
        {
          name: "Start of week",
          key: "start_of_week",
          type: "enum",
          values: ["monday", "sunday"],
          show: true,
        },
        {
          name: "Timezone",
          key: "timezone",
          type: "string",
          show: true,
        },
        {
          name: "Local name",
          key: "local_name",
          type: "string",
          show: true,
        },
        {
          name: "Currency",
          key: "default_currency",
          type: "enum",
          values: [],
          label: "code",
          selectKey: "code",
          show: true,
        },
        {
          name: "Default webshop",
          key: "default_webshop",
          type: "enum",
          values: [],
          label: "name",
          selectKey: "id",
          show: true,
        },
        {
          name: "Default VAT rate",
          key: "default_vat_rate",
          type: "number",
          postfix: "%",
          editable: false,
          show: true,
        },
        {
          name: "Need post code",
          key: "need_post_code",
          type: "boolean",
          show: true,
        },
        {
          name: "Post code local name",
          key: "post_code_local_name",
          type: "string",
          show: true,
        },
        {
          name: "Address style",
          key: "address_style",
          type: "enum",
          values: [
            {
              id: 1,
              name: "Post code, county and city input",
            },
            {
              id: 2,
              name: "County and city selector",
            },
            {
              id: 3,
              name: "Autosuggest",
            },
          ],
          label: "name",
          selectKey: "id",
          show: true,
        },
        {
          name: "Need state",
          key: "need_state",
          type: "boolean",
          show: true,
        },
        {
          name: "State type",
          key: "state_type",
          type: "enum",
          values: Object.values(StateTypes),
          label: "name",
          selectKey: "id",
          show: true,
        },
        {
          name: "Need area",
          key: "need_area",
          type: "boolean",
          show: true,
        },
        {
          name: "In EU",
          key: "is_eu",
          type: "boolean",
          show: true,
        },
        {
          name: "In UN",
          key: "is_un",
          type: "boolean",
          show: true,
        },
      ],
    };
  },
  computed: {
    webshops() {
      return this.store.state.webshops;
    },
    filterCountries() {
      if (this.filter.length < 1) {
        return this.countries;
      }
      if (this.filter === "eu") {
        return this.countries.filter((e) => e.is_eu);
      }
      if (this.filter === "un") {
        return this.countries.filter((e) => e.is_un);
      }
      return this.countries.filter(
        (e) => e.name.toLowerCase().indexOf(this.filter) > -1
      );
    },
  },
  watch: {
    filter() {
      this.key++;
    },
  },
  mounted() {
    this.rows[8].values = this.webshops;
    this.load();
  },
  methods: {
    load() {
      http.fetch("/currencies/all").then((data) => {
        this.rows[7].values = data;
      });
      http.fetch("/countries/details").then((data) => {
        this.countries = data;
      });
    },
    update(data, v, e) {
      let row = {};
      row[v] = e;
      http.fetch(`/countries/${data.id}`, row, true, "PUT");
    },
  },
};
</script>
