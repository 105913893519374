import i18n from "../i18n";

const StateTypes = {
  0: { id: 0, name: i18n.global.t("stateTypes.state") },
  1: { id: 1, name: i18n.global.t("stateTypes.county") },
  2: { id: 2, name: i18n.global.t("stateTypes.region") },
  3: { id: 3, name: i18n.global.t("stateTypes.province") },
};

export default StateTypes;
